import { reactive, computed } from 'vue'
import { decodeCookie, readCookies } from '@u/cookies'

const state = reactive({
  cookieInfo: null
})

const cookieInfo = computed(() => state.cookieInfo)

const writeCookie = () => {
  state.cookieInfo = (!readCookies('token')) ? null : decodeCookie('token')
}

const userInfo = {
  cookieInfo,
  writeCookie
}

export default userInfo;