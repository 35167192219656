<template>
  <div class="form">
    <form action="/" method="post" id="loginform" @submit="authlogin" class="form form__form">
      <p>Please login to access the voting platform.</p>
      <div class="form__field-group">
        <label class="label">Member Number:</label>
        <input class="form__field" v-model="memberInput" required type="text" name="membernum" js-input="membernum"/>
      </div>
      <div class="form__field-group">
        <label class="label">Email:</label>
        <input class="form__field" v-model="emailInput" required type="text" name="email" js-input="email"/>
      </div>
      <div class="form__field-group">
        <input class="btn btn--small" type="submit" value="Log In"/>
      </div>

      <p class="form__error" :class="{'visible': loginError}">
        <strong>Login Error</strong> Check your email address and membership number and try again.
      </p>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import userInfo from '@u/setup-cookies'
import { writeCookies } from '@u/cookies';

export default {
  name: 'LoginForm',

  setup() {
    const {writeCookie} = userInfo;
    return {writeCookie}
  },

  data() {
    return {
      memberInput: '',
      emailInput: '',
      loginError: false,
    }
  },

  methods: {
    authlogin(event) {
      event.preventDefault();

      axios
        .post(`${process.env.VUE_APP_API_URL}login/`, {
          email: this.emailInput,
          membernum: this.memberInput,
          loggedIn: true
        }, {
        withCredentials: true,
        headers: {
          //'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json'
        }
        })
        .then( (response) => {
          writeCookies(response.data.token)
          this.writeCookie()
          this.$router.push('/member')
        })
        .catch((error) => {
          this.loginError = true
          console.log(error);
        });
    }
  }
}
</script>