<template>
  <div v-if="cookieInfo">
    <PollEditorForm v-if="cookieInfo.userRole === 'admin' && formContent" :formContent="formContent" />

    <p v-else>You can't access this</p>
  </div>

  <p v-else>Not logged in!</p>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from 'vue-router';
import axios from 'axios';
import { readCookies } from '@u/cookies'
import userInfo from '@u/setup-cookies'
import PollEditorForm from '@c/PollEditorForm';

export default {
  name: 'PollEditor',

  components: {
    PollEditorForm
  },

  setup() {
    const formContent = ref(null);
    const loading = ref(true);
    const error = ref(null);
    const route = useRoute();

    function fetchData() {
      loading.value = true;
      const headers = {
        'Content-Type': 'application/json',
      }

      if(readCookies('token')) {
        headers['x-access-token'] = readCookies('token')
      }

      axios
        .get(`${process.env.VUE_APP_API_URL}polls/${route.params.id}`, {
          withCredentials: true,
          headers: headers
        })
        .then(res => {
          formContent.value = res.data;
        })
        .catch(err => {
          error.value = err;

          if (err.json) {
            return err.json.then(json => {
              error.value.message = json.message;
            });
          }
        })
        .then(() => {
          loading.value = false;
        });
    }

    onMounted(() => {
      fetchData();
    });

    const {cookieInfo} = userInfo;

    return {
      formContent,
      loading,
      error,
      cookieInfo
    };
  },
}
</script>