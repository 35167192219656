<template>
  <div class="col-xs-12 col-sm-6 col-md-4">
    <div class="box poll">
      <header class="poll__header">
        <PollInfo :poll="poll" :memberVoted="memberVoted" :pollBox="true" />
        <h2 v-html="poll.title" />
      </header>

      <div class="poll__content" v-if="poll.excerpt" v-html="poll.excerpt" />

      <poll-results v-if="!isFutureExpiry(poll.expiry)" :poll="poll" :listing="true" />

      <router-link :to="`/polls/${poll._id}`" class="btn btn--large btn--block poll__read-more" v-html="!isFutureExpiry(poll.expiry) || memberVoted ? 'Read More': 'Vote now'" />

      <router-link v-if="cookieInfo.userRole === 'admin'" :to="`/polls/${poll._id}/edit`" className="poll__edit-button"><Icons :icon="'edit'" /><span class="visually-hidden">Edit</span></router-link>
    </div>
  </div>
</template>

<script>
import userInfo from '@u/setup-cookies'

import PollResults from './PollResults.vue';
import PollInfo from './PollInfo.vue';
export default {
  components: { PollResults, PollInfo },
  name: 'PollBox',

  props: {
    poll: {
      type: Object,
      default: null
    }
  },

  setup() {
    const {cookieInfo} = userInfo;
    return {cookieInfo}
  },

  data() {
    return {
      today: new Date(),
    }
  },

  computed: {
    memberVoted() {
      return this.poll.membersVotes.some(vote => vote === this.cookieInfo.userId)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>