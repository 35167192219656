<template>
  <div class="poll-editor">
    <form action="" @submit="handleSubmit" class="form form__form">
      <div class="form__field-group">
        <label class="label" for="title">Title</label>
        <input class="form__field" type="text" name="title" id="title" required v-model="poll.title">
      </div>

      <div class="form__field-group">
        <label class="label" for="expiry">Expiry date</label>
          <datepicker
            v-model="poll.expiry"
            class="form__field"
            name="expiry"
            id="expiry"
          />
      </div>

      <div class="form__field-group">
        <label class="label" for="excerpt">Excerpt</label>
        <textarea class="form__field" name="excerpt" rows="4" cols="50" v-model="poll.excerpt" />
      </div>

      <div class="form__field-group">
        <label class="label" for="description">Description</label>
        <VueEditor name="description" v-model="poll.description" />
      </div>

      <div class="form__field-group">
        <h3 class="label">Options</h3>
        <div class="form__options">
          <div class="form__option" v-for="(option, index) in poll.options" :key="index">
            <input class="form__field" type="text" name="label" id="label" required v-model="poll.options[index].label">
            <input type="hidden" name="value" id="value" :value="optionValue(index)">

            <button class="form__option-btn" v-if="poll.options.length === index + 1" @click="addOption">+<span class="visually-hidden">Add option</span></button>

            <button class="form__option-btn" v-if="poll.options.length > 1" @click="removeOption(index)">-<span class="visually-hidden">Remove option</span></button>
          </div>
        </div>
      </div>

      <div class="form__field-group form__field-group--submit">
        <router-link class="btn btn--small btn--outline" :to="(formContent) ? `/polls/${poll._id}`: '/polls'">back</router-link>
        <input class="btn btn--small form__submit" type="submit" :value="'Save'"/>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import { readCookies } from '@u/cookies';
import datepicker from 'vue3-datepicker'
import { VueEditor } from 'vue3-editor'

export default {
  name: 'PollEditorForm',

  components: {
    datepicker,
    VueEditor
  },

  props: {
    formContent: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      poll: {
        title: '',
        expiry: null,
        excerpt: '',
        description: '',
        permalink: '',
        options: [
          {
            label: '',
            value: '',
            votes: 0
          }
        ],
        totalVotes: 0
      }
    }
  },

  methods: {
    addOption() {
      this.poll.options.push({label: '', value: '', votes: 0})
    },

    removeOption(idx) {
      this.poll.options.splice(idx, 1)
    },

    handleSubmit(e) {
      e.preventDefault();

      this.poll.permalink = this.handleize(this.poll.title);

      if(this.formContent) {
        axios
          .put(`${process.env.VUE_APP_API_URL}polls/${this.poll._id}`, {
            poll: this.poll
          }, {
              withCredentials: true,
              headers: {
                'x-access-token': readCookies('token'),
                'Content-Type': 'application/json'
              }
          })
          .then( () => {
            console.log('form submitted')
            this.$router.push(`/polls/${this.poll._id}`)
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
      axios
        .post(`${process.env.VUE_APP_API_URL}polls`, {
          poll: this.poll
        }, {
            withCredentials: true,
            headers: {
              'x-access-token': readCookies('token'),
              'Content-Type': 'application/json'
            }
        })
        .then( (response) => {
          console.log(response)
          if(!this.formContent) {
            this.$router.push(`/polls/${response.data._id}/edit`)
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      }
    },

    optionValue(index) {
      this.poll.options[index].value = this.handleize(this.poll.options[index].label)
    }
  },

  mounted() {
    if(this.formContent) {
      this.poll = this.formContent;
      this.poll.expiry = new Date(this.formContent.expiry)
    }
  }
}
</script>