<template>
  <Header />

  <div className="container main">
    <router-view></router-view>
  </div>

  <Footer />
</template>

<script>
import 'vue-router'
import userInfo from '@u/setup-cookies'
import Header from '@c/Header'
import Footer from '@c/Footer'

export default {
  name: 'CCFCVoting',
  components: {
    Footer,
    Header,
  },

  setup() {
    const {cookieInfo, writeCookie} = userInfo;
    return {cookieInfo, writeCookie}
  },

  mounted() {
    this.writeCookie();
  }
}
</script>


<style lang="scss">
  @font-face {
    font-family: 'galano_grotesque_demobold';
    src:	url('./assets/fonts/galanogrotesquedemo-bold-webfont.woff2') format('woff2'),
        url('./assets/fonts/galanogrotesquedemo-bold-webfont.woff') format('woff'),
        url('./assets/fonts/galanogrotesquedemo-bold-webfont.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'galano_grotesque_full_bold';
    src: url('./assets/fonts/Galano_Grotesque_Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
  }

  @import '@a/scss/base';
</style>