<template>
  <div>
    <LoginForm />
  </div>
</template>

<script>
import userInfo from '@u/setup-cookies'
import LoginForm from '@c/LoginForm'

export default {
  name: 'HomePage',

  setup() {
    const {cookieInfo} = userInfo;
    return {cookieInfo}
  },

  mounted() {
    if(this.cookieInfo) {
      this.$router.push('/member')
    }
  },

  components: {
    LoginForm
  }
}
</script>
