<template>
  <div
    class="poll-info"
    :class="[memberVoted && !pollBox ? 'poll-info--voted': 'poll-info--vote']"
  >
    <template v-if="memberVoted && !pollBox">
      <div class="poll-info__icon">
        <Icons :icon="'voted'" />
      </div>

      <p v-if="isFutureExpiry(poll.expiry)" class="poll-info__tag tag">
        <strong>{{ daysLeft(poll.expiry) }}</strong> Days Left
      </p>

      <p class="poll-info__tag tag tag--voted">
        Voted
      </p>

      <p class="poll-info__voted">
        You voted:<br />
        <span class="h2 poll-info__member-vote">
          {{ pollVote }}
        </span>
      </p>
    </template>

    <template v-else>
      <Icons :icon="'calendar'" />

      <p class="poll-info__date">
        {{ isExpiredTitle }}<br />
        <time :datetime="poll.expiry" v-html="expiryDateReadable(poll.expiry, true)" />
      </p>

      <p
        v-if="isFutureExpiry(poll.expiry)"
        class="poll-info__tag tag"
      >
        <strong>{{ daysLeft(poll.expiry) }}</strong> Days Left
      </p>
    </template>
  </div>
</template>

<script>
import userInfo from '@u/setup-cookies'

export default {
  name: 'poll-info',

  setup() {
    const {cookieInfo} = userInfo;
    return {cookieInfo}
  },

  props: {
    poll: {
      type: Object,
      default: null
    },
    memberVoted: {
      type: Boolean,
      default: false
    },
    pollBox: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    isExpiredTitle() {
      return (this.isFutureExpiry(this.poll.expiry)) ? 'Ending' : 'Ended';
    },

    pollVote() {
      const thisPoll = this.cookieInfo.votedPolls.find(poll => poll.pollId === this.poll._id)
      return this.poll.options.find(option => option.value === thisPoll.value).label
    }
  }
}
</script>
