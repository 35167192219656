<template>
  <div>
    <div v-if="!loading && poll">
      <div class="row">
        <div class="col-xs-12 col-sm-8">
          <div class="box poll">
            <router-link v-if="cookieInfo.userRole === 'admin'" :to="`/polls/${poll._id}/edit`" className="poll__edit-button"><Icons :icon="'edit'" /><span class="visually-hidden">Edit</span></router-link>
            <h1 v-html="poll.title" class="poll__title" />

            <ChartContainer
              v-if="!isFutureExpiry(poll.expiry)"
              :options="poll.options" :totalVotes="poll.totalVotes" />

            <div class="poll__content" v-html="poll.description" />
          </div>
        </div>

        <div class="col-xs-12 col-sm-4">
          <aside class="poll__sidebar">
            <PollInfo :poll="poll" :memberVoted="memberVoted" />

            <PollVote
              v-if="isFutureExpiry(poll.expiry) && !memberVoted"
              :options="poll.options"
              :pollId="poll._id"
              :preVoted="hasPreVoted"
            />
          </aside>
        </div>
      </div>
    </div>

    <p v-if="loading">
      Still loading..
    </p>

    <p v-if="error" v-html="error" />
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from 'vue-router';
import axios from 'axios';
import { readCookies } from '@u/cookies'
import userInfo from '@u/setup-cookies'

import PollInfo from '@c/Poll/PollInfo'
import PollVote from '@c/Poll/PollVote'
import ChartContainer from '@c/Poll/ChartContainer.vue';

export default {
  name: 'SinglePollPage',

  components: {
     PollInfo,
     PollVote,
     ChartContainer
  },

  setup() {
    const poll = ref(null);
    const hasPreVoted = ref(null);
    const loading = ref(true);
    const error = ref(null);
    const route = useRoute();
    const {cookieInfo} = userInfo;

    function fetchData() {
      loading.value = true;
      const headers = {
        'Content-Type': 'application/json',
      }

      if(readCookies('token')) {
        headers['x-access-token'] = readCookies('token')
      }

      axios
        .get(`${process.env.VUE_APP_API_URL}polls/${route.params.id}`, {
          withCredentials: true,
          headers: headers
        })
        .then(res => {
          poll.value = res.data;

          hasPreVoted.value = cookieInfo.value.votedPolls.some(v => v === poll.value._id)
        })
        .catch(err => {
          error.value = err;

          if (err.json) {
            return err.json.then(json => {
              error.value.message = json.message;
            });
          }
        })
        .then(() => {
          loading.value = false;
        });
    }

    onMounted(() => {
      fetchData();
    });

    return {
      poll,
      hasPreVoted,
      loading,
      error,
      cookieInfo
    };
  },

  computed: {
    memberVoted() {
      return this.poll.membersVotes.some(vote => vote === this.cookieInfo.userId)
    }
  }
}
</script>