import { createApp } from 'vue'
import mixins from './mixins'
import router from './routes'
import Icons from '@c/Icons.vue'
import App from './App.vue'

const app = createApp(App);

app.use(router)

app.mixin(mixins)

app.component('Icons', Icons)

app.mount('#app')
