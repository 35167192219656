const mixins = {
  methods: {
    memberExpired(expiryDateValue) {
      const today = new Date();
      const expiryDate = new Date(expiryDateValue);
      return today > expiryDate
    },

    percentage(votes, total) {
      const value = Number(((votes * 100) / total).toFixed(2));
      return (Number.isNaN(value)) ? '0%' : `${value}%`
    },

    handleize(string) {
      return string.replace(/[^\w ]+/g,'').replace(/ +/g,'-').toLowerCase()
    },

    expiryDateReadable(expiryDate, short = false) {
      const monthNames = (!short)
        ? ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        : ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const date = new Date(expiryDate);
      const day = date.getDate();
      const month = monthNames[date.getMonth()];
      const year = date.getFullYear();
      return `${day}<sup class="sup">${this.nth(day)}</sup> ${month} ${year}`;
    },

    nth(d) {
      if (d > 3 && d < 21) return 'th';
      switch (d % 10) {
        case 1:  return "st";
        case 2:  return "nd";
        case 3:  return "rd";
        default: return "th";
      }
    },

    daysLeft(date) {
      const today = new Date()
      const expiryDate = new Date(date)
      return Math.round(Math.abs((expiryDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)))
    },

    isFutureExpiry(date) {
      const today = new Date()
      const expiryDate = new Date(date)
      return today < expiryDate
    }
  }
}

export default mixins