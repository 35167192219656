/**
* Utility for cookies
* @namespace cookiesUtility
*/

import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';

export function writeCookies(token) {
  Cookies.set('token', token, { path: '/', expires: 1 });
}

export function readCookies(cookieName) {
  return Cookies.get(cookieName);
}

export function deleteCookies(cookieName) {
  Cookies.set(cookieName, '', { path: '/', expires: -1 });
}

export function decodeCookie(cookieName) {
  let cookie = Cookies.get(cookieName);
  return jwt_decode(cookie);
}