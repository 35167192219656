<template>
  <div v-if="listing" class="bars">
    <div v-for="(option, index) in reorderResults" :key="index" class="bars__option">
      <h3 class="bars__name" v-html="option.label" />

      <div class="bar">
        <div class="bar__value" :style="`width: ${percentage(option.votes, poll.totalVotes)};`" />
      </div>

      <div class="bars__numbers">
        <span>{{ option.votes }}</span> / {{ poll.totalVotes }}
      </div>
    </div>
  </div>

  <div v-else>
    <p>Votes: {{ poll.totalVotes }}</p>

    <div v-for="(option, index) in poll.options" :key="index">
      <h3>{{ option.label }} <em v-html="option.value" /></h3>
      <p>Votes: {{ option.votes }}</p>
      <p>{{ percentage(option.votes, poll.totalVotes) }}</p>
    </div>

    <chart-container :options="poll.options" :totalVotes="poll.totalVotes" style="width:400px;"/>
  </div>
</template>

<script>
import ChartContainer from './ChartContainer.vue';

export default {
  name: 'PollResults',

  components: {
    ChartContainer
  },

  props: {
    poll: {
      type: Object,
      default: null
    },

    listing: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    reorderResults() {
      const pollOptions = this.poll.options;
      return pollOptions.sort((a, b) => { return b.votes - a.votes });
    }
  }
}
</script>

<style lang="scss" scoped>
  .bars {
    &__option {
      align-items: center;
      display: flex;
      flex-wrap: wrap;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    &__name {
      color: $grey-dark;
      font-family: "Droid Sans", sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      text-transform: uppercase;
      width: 100%;
    }

    &__numbers {
      color: $grey-dark;
      flex: 1;
      font-size: 14px;
      text-align: right;
    }
  }

  .bar {
    background: $grey;
    border-radius: 2.5px;
    height: 4px;
    position: relative;
    width: 75%;

    &__value {
      background: $red;
      border-radius: 2.5px;
      height: 4px;
      left: 0;
      position: absolute;
      top: 0;
    }

  }

  $colors: $red, $red-light, $red-lighter;

  @for $i from 1 through length($colors) {
    $c: nth($colors, $i);

    .bars__option:nth-child(#{$i}) {
      .bar {
        &__value {
          background: $c;
        }
      }

      .bars__numbers span {
        color: $c;
      }
    }
  }
</style>