<template>
    <chart :chartData="dataSetup" :chartOptions="state.chartOptions" />
</template>
<script>
import Chart from './Chart.vue';
import pattern from 'patternomaly';

export default {
  components: {
    Chart
  },

  props: {
    options: {
      type: Array,
      default: null
    },
    totalVotes: {
      type: Number,
      default: 1
    }
  },

  data() {
    return {
      state: {
        chartOptions: {
          responsive: true,
          legend: {
            position: 'bottom',
          }
        }
      }
    }
  },

  computed:{
    dataSetup() {
      const labelsArray = this.options.map(option => `${option.value} (${this.percentage(option.votes, this.totalVotes)})`);
      const datasetArray = this.options.map(option => option.votes);

      const datasetObj = [
        {
          data: datasetArray,
          backgroundColor: [
            pattern.draw('diamond', '#cc65fe'),
            pattern.draw('triangle', '#ffce56'),
            pattern.draw('square', '#ff6384'),
            pattern.draw('circle', '#36a2eb'),
          ],
          borderColor: 'rgba(255, 255, 255, 1)',
          borderWidth: 10,
          hoverBorderColor: 'rgba(255, 255, 255, 1)',
          cutout: '10%'
        }
      ];

      const data = {
        labels: labelsArray,
        datasets: datasetObj,
      }

      return data
    }
  }
}
</script>