<template>
  <div v-if="cookieInfo">
    <PollEditorForm v-if="cookieInfo.userRole === 'admin'" />
  </div>

  <p v-else>Not logged in!</p>
</template>

<script>
import userInfo from '@u/setup-cookies'
import PollEditorForm from '@c/PollEditorForm';

export default {
  components: {
    PollEditorForm
  },

  setup() {
    const {cookieInfo} = userInfo;
    return {cookieInfo}
  }
}
</script>