<template>
  <div class="container">
    <div class="row">
      <div class="col col-xs-12">
        <div class="user-box box">
          <Icons :icon="'logo'" />

          <div class="user-box__info" v-if="cookieInfo">
            <div class="user-box__title">
              <h2 class="h1">{{ cookieInfo.userEmail }}</h2>
              <button v-on:click="logout" className="btn btn--small">Logout</button>
            </div>

            <div class="user-box__info-box user-box__info-box--expiry">
              <Icons :icon="'calendar'" />
              <p class="user-box__info-content">
                <span class="user-box__info-header">{{ isExpiredTitle }}</span>
                <time class="user-box__info-value"
                  :class="{'expired':!isFutureExpiry(cookieInfo.expiryDate)}"
                  :datetime="cookieInfo.expiryDate" v-html="expiryDateReadable(cookieInfo.expiryDate, true)"
                />
              </p>
            </div>

            <div class="user-box__info-box user-box__info-box--expiry">
              <Icons :icon="'card'" />
              <p class="user-box__info-content">
                <span class="user-box__info-header">Member Number</span>
                <span class="user-box__info-value">{{cookieInfo.userNumber}}</span>
              </p>
            </div>

            <div v-if="memberExpired(cookieInfo.expiryDate)" class="user-box__expiry-info">
              <p>Your membership expired on <strong><span v-html="expiryDateReadable(cookieInfo.expiryDate)" /></strong>.<br>
              Please renew it in <a href="https://membermojo.co.uk/ccfc/" target="_blank">Membermojo</a> if you want to be able to vote</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import userInfo from '@u/setup-cookies'
import { deleteCookies } from '@u/cookies'

export default {
  setup() {
    const {cookieInfo, writeCookie} = userInfo;
    return {cookieInfo, writeCookie}
  },

  computed: {
    isExpiredTitle() {
      return (this.isFutureExpiry(this.cookieInfo.expiryDate)) ? 'Membership Expiring' : 'Membership Expired';
    }
  },

  methods: {
    logout() {
      axios
        .get(`${process.env.VUE_APP_API_URL}logout/`)
        .then( () => {
          deleteCookies('token')
          this.writeCookie()
          this.$router.push('/')
        })
        .catch(function (error) {
          console.log(error);
      });
    }
  }
}
</script>

<style lang="scss" scoped>
  .col {
    margin-top: -124px;
    padding-bottom: 0;
    padding-top: 34px;
  }

  .user-box {
    background: linear-gradient(0deg, rgba($white,1) 50%, rgba($white,0.7) 100%);
    display: flex;
    flex-direction: column;
    padding: 15px 48px 20px;
    position: relative;
    z-index: 1;

    &__info {
      display: flex;
      flex-wrap: wrap;
    }

    &__title {
      margin-bottom: 16px;
      width: 100%;
    }

    &__info-box {
      align-items: center;
      display: flex;
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      p {
        margin-left: 10px;
      }
    }
    .expired {
      color: $red;
    }

    &__info-content {
      display: flex;
      flex-direction: column;
    }

    &__expiry-info {
      font-size: 0.9rem;
      margin: 2em 0 0;

      &, a {
        color: $red;
      }

      strong {
        font-family: 'galano_grotesque_full_bold';
      }
    }

    &__info-header {
      color: $grey-dark;
      font-size: 12px;
      text-transform: uppercase;
    }

    &__info-value {
      color: $body-color;
      font-family: 'galano_grotesque_full_bold';
      font-size: 16px;
      line-height: 1;
    }


    @include mq($from: tablet) {
      align-items: center;
      flex-direction: row;

      .user-box {
        padding: 15px 48px;

        &__info {
          margin-left: 40px;
        }

        &__info-box {
          margin-bottom: 0;
          margin-right: 80px;
          width: auto;
        }
      }
    }
  }
</style>
