<template>
  <header class="header">
    <Menu :menu-style="'header'" />

    <div class="header__wave">
      <svg width="1440" height="401" viewBox="0 0 1440 401" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1090.13 223.358C894.17 130.758 891.331 93.0692 734.328 117.498C577.324 141.928 482.835 15.2183 330.538 -5.75277C178.242 -26.7238 59.8594 -3.6909 -13 18.6761C-13 117.245 -13 508 -13 508L1489 508L1489 225.367C1489 225.367 1286.44 316.128 1090.13 223.358Z" fill="url(#paint0_linear)"/>
        <defs>
        <linearGradient id="paint0_linear" x1="1107.2" y1="75.7793" x2="836.154" y2="479.78" gradientUnits="userSpaceOnUse">
        <stop stop-color="#E10000"/>
        <stop offset="0.584158" stop-color="#FF3738"/>
        <stop offset="1" stop-color="#C9000C"/>
        </linearGradient>
        </defs>
      </svg>
    </div>

    <UserBox />
  </header>

</template>

<style lang="scss">
.header {
  margin-bottom: 60px;
  width: 100%;

  &__wave {
    background: $red-dark;
    height: 160px;
    overflow: hidden;
    position: relative;
    width: 100%;

    svg {
      height: 100vh;
      width: 100vw;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

  }

  @include mq($from: tablet) {
    margin-bottom: 80px;
    &__wave {
      height: 240px;
    }
  }

  @include mq($from: desktop) {
    &__wave {
      height: 300px;
    }
  }
}
</style>

<script>
import UserBox from './UserBox'
import Menu from './Menu'

export default {
  components: {
    Menu,
    UserBox
  }
}
</script>