<template>
  <div v-if="!loading && data" class="polls">
    <div v-if="data.future.length && !memberExpired(cookieInfo.expiryDate)" class="row polls__row">
      <h2 class="polls__title col-xs-12">Ongoing Polls</h2>
      <template v-for="(poll, index) of data.future" :key="index">
        <PollBox :poll="poll" />
      </template>
    </div>

    <div class="row polls__row">
      <h2 class="polls__title col-xs-12">Past Polls</h2>
      <template v-for="(poll, index) of data.past" :key="index">
        <PollBox :poll="poll" />
      </template>
    </div>
  </div>

  <p v-if="loading">
    Still loading..
  </p>

  <p v-if="error" v-html="error" />
</template>

<style lang="scss">
  .polls {
    &__title {
      margin-bottom: 0;
      text-transform: uppercase;
    }

    &__row {
      &:not(:first-child) {
        margin-top: 40px;
      }
    }
  }
</style>

<script>
import userInfo from '@u/setup-cookies'
import { ref, onMounted } from "vue";
import axios from 'axios';
import { readCookies } from '@u/cookies'

import PollBox from '@c/Poll/PollBox'

export default {
  name: 'PollsList',

  components: {
     PollBox
  },

  setup() {
    const data = ref(null);
    const loading = ref(true);
    const error = ref(null);
    const {cookieInfo} = userInfo;

    function fetchData() {
      loading.value = true;
      const headers = {
        'Content-Type': 'application/json',
      }

      if(readCookies('token')) {
        headers['x-access-token'] = readCookies('token')
      }

      axios
        .get(`${process.env.VUE_APP_API_URL}polls`, {
          withCredentials: true,
          headers: headers
        })
        .then(res => {
          var responses = res.data.slice(0);
          responses.sort(function(a,b) {
            return new Date(b.expiry) - new Date(a.expiry);
          });

          const splitResponses = responses.reduce((result, element) => {
            result[new Date() < new Date(element.expiry) ? 'future': 'past'].push(element);
            return result
          },
          {'future': [], 'past': []})

          data.value = splitResponses;
        })
        .catch(err => {
          error.value = err;

          if (err.json) {
            return err.json.then(json => {
              error.value.message = json.message;
            });
          }
        })
        .then(() => {
          loading.value = false;
        });
    }

    onMounted(() => {
      fetchData();
    });

    return {
      data,
      loading,
      error,
      cookieInfo
    };
  },

  methods: {
    publicPoll(poll) {
      if (this.isFutureExpiry(poll.expiry)) {
        return Boolean(this.cookieInfo);
      }

      return true;
    }
  }
}
</script>
