<template>
  <div v-if="cookieInfo">
    <h2>Your Member Page</h2>
    <p>Member number: {{ cookieInfo.userNumber }}</p>
    <p v-if="cookieInfo.userRole">Member role: {{ cookieInfo.userRole }}</p>
    <div>
      <div v-if="memberExpired(cookieInfo.expiryDate)">
        <p>Your membership expired on <span v-html="expiryDateReadable(cookieInfo.expiryDate)" />.</p>
        <p>Please renew it in <a href="https://membermojo.co.uk/ccfc/" target="_blank">Membermojo</a> if you want to be able to vote</p>
      </div>

      <div v-else>
      <p>Membership expiring on <span v-html="expiryDateReadable(cookieInfo.expiryDate)" /></p>
      </div>
    </div>
  </div>

  <p v-else>Not logged in!</p>
</template>

<script>
import userInfo from '@u/setup-cookies'

export default {
  setup() {
    const {cookieInfo} = userInfo;
    return {cookieInfo}
  }
}
</script>