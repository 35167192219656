<template>
  <nav class="main-menu" :class="`main-menu--${menuStyle}`">
    <h2
      v-if="menuStyle === 'footer'"
      class="main-menu__item h3"
    >
      Menu
    </h2>

    <router-link
      class="main-menu__item"
      :class="{'active' : this.$route.name === 'Home'}"
      to="/"
    >
      Home
    </router-link>

    <router-link
      class="main-menu__item"
      :class="{'active' : this.$route.name === 'NewPoll'}" v-if="cookieInfo && cookieInfo.userRole === 'admin'"
      to="/new"
    >
      New Poll
    </router-link>

    <router-link
      class="main-menu__item"
      :class="{'active' : this.$route.name === 'PollsList' || this.$route.name === 'SinglePoll' || this.$route.name === 'SinglePollEdit'}" v-if="cookieInfo"
      to="/polls"
    >
      Polls
    </router-link>

    <router-link
      class="main-menu__item"
      :class="{'active' : this.$route.name === 'FAQs'}"
      to="/faq"
    >
      FAQs
    </router-link>
  </nav>
</template>

<script>
import userInfo from '@u/setup-cookies'

export default {
  name: 'Menu',

  props: {
    menuStyle: {
      type: String,
      default: ''
    }
  },

  setup() {
    const {cookieInfo} = userInfo;
    return {cookieInfo}
  },
}
</script>

<style lang="scss">
  .main-menu {
    $parent: &;

    &__item {
      position: relative;
      text-decoration: none;

      &.active {
        font-weight: 700;
      }
    }

    &--header {
      background: $white;
      display: flex;
      height: 70px;
      justify-content: center;

      #{$parent}__item {
        color: $body-color;
        height: 100%;
        line-height: 70px;
        padding: 0 16px;

        &.active {
          color: $red;

          &::before {
            background: currentColor;
            border-radius: 0 0 3px 3px;
            content: '';
            height: 4px;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
          }
        }
      }
    }

    &--footer {
      #{$parent}__item {
        color: $white;
        display: block;
        width: fit-content;

        &:not(:last-child) {
          margin-bottom: 1em;
        }
      }
    }
  }
</style>