import {createRouter, createWebHistory } from 'vue-router'

import Home from '../layouts/Home.vue'
import Polls from '../layouts/Polls.vue'
import Poll from '../layouts/Poll.vue'
import PollEditor from '../layouts/PollEditor.vue'
import VoteConfirm from '../layouts/VoteConfirm.vue'
import Member from '../layouts/Member.vue'
import NewPoll from '../layouts/NewPoll.vue'
import FAQs from '../layouts/FAQs.vue'

const routes = [
  { path: '/', component: Home, name: 'Home' },
  { path: '/polls', component: Polls, name: 'PollsList' },
  { path: '/polls/:id', component: Poll, name: 'SinglePoll' },
  { path: '/polls/:id/edit', component: PollEditor, name: 'SinglePollEdit' },
  { path: '/confirm-vote/:id/:otp', component: VoteConfirm, name: 'VoteConfirm' },
  { path: '/member', component: Member, name: 'MemberPage' },
  { path: '/new', component: NewPoll, name: 'NewPoll' },
  { path: '/faq', component: FAQs, name: 'FAQs' },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

export default router;