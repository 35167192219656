<script>
import { defineComponent } from 'vue'
import { Doughnut } from 'vue3-chart-v2'

export default defineComponent ({
  extends: Doughnut,

  props: {
    chartData: {
      type: Object,
      default: null
    },
    chartOptions: {
      type: Object,
      default: null
    },
  },

  mounted () {
    this.renderChart(this.chartData, this.chartOptions)
  }
})
</script>