<template>
  <footer class="footer">
    <div class="footer__content container">
      <div class="row">
        <div class="col col-xs-12 col-sm-6 col-md-8 footer__menu">
          <Icons :icon="'logo'" />
          <Menu :menu-style="'footer'" />
        </div>
        <div class="col col-xs-12 col-sm-6 col-md-4">
          footer
        </div>
      </div>
    </div>

    <div class="footer__footer">
      <a href="https://www.claptoncfc.co.uk/" target="_blank">
        https://www.claptoncfc.co.uk/
      </a>
    </div>
  </footer>
</template>

<script>
import Menu from './Menu'

export default {
  components: {
    Menu,
  }
}
</script>