<template>
  <form v-if="!hasVoted" :id="pollId" @submit="voteSubmit" className="poll-form">
    <div>
      <label v-for="(option, index) in options" :key="index" :for="option.value" class="poll-form__option">
        <input type="radio" :id="option.value" name="options" :value="option.value" v-model="selectedOption" required class="visually-hidden" />
        <span class="poll-form__label">{{ option.label }}</span>
      </label>
    </div>
    <input type="submit" className="btn btn--small poll-form__btn" value="Submit Vote" />
  </form>

  <div v-else class="poll-voted">
    <p>Check your emails to confirm your vote</p>
    <button class="btn-link btn-link--small" @click="hasVoted = false">Or change your vote</button>
  </div>
</template>

<script>
import axios from 'axios';
import { readCookies } from '@u/cookies';

export default {
  name: 'PollVote',
  props: {
    options: {
      type: Array,
      default: null
    },
    pollId: {
      type: String,
      default: ''
    },
    preVoted: {
      type: Boolean,
      defaule: false
    }
  },

  data() {
    return {
      selectedOption: null,
      hasVoted: this.preVoted,
    }
  },

  methods: {
    voteSubmit(e) {
      e.preventDefault();
      axios
        .post(`${process.env.VUE_APP_API_URL}votes`, {
          vote: {
            pollId: this.pollId,
            voteValue: this.selectedOption
          }
        }, {
          withCredentials: true,
          headers: {
            'x-access-token': readCookies('token'),
            'Content-Type': 'application/json'
          }
      })
      .then(() => {
        this.hasVoted = true;
      })
      .catch(function (error) {
        console.log(error);
      });
      }
  }
}
</script>