<template>
  <div v-if="!loading">
    <div class="poll-confirm" v-if="!voteRegistered">
      <h1>Confirm your vote</h1>
      <p>To confirm your vote for the poll <strong>{{ poll.title }}</strong> click the link below</p>
      <form @submit="voteSubmit">
        <input type="submit" className="btn btn--small poll-form__btn" value="Confirm Vote" />
      </form>
    </div>

    <div v-else>VOTE ALREADY STORED</div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from 'vue-router';
import axios from 'axios';
import { readCookies } from '@u/cookies';
import userInfo from '@u/setup-cookies'

export default {
  name: 'VoteConfirm',

  setup() {
    const poll = ref(null);
    const loading = ref(true);
    const voteRegistered = ref(false);
    const error = ref(null);
    const route = useRoute();
    const {cookieInfo} = userInfo;

    function fetchData() {
      loading.value = true;
      const headers = {
        'Content-Type': 'application/json',
      }

      if(readCookies('token')) {
        headers['x-access-token'] = readCookies('token')
      }

      axios
        .get(`${process.env.VUE_APP_API_URL}polls/${route.params.id}`, {
          withCredentials: true,
          headers: headers
        })
        .then(res => {
          poll.value = res.data;
          voteRegistered.value = poll.value.membersVotes.some(v => v === cookieInfo.value.userId)
        })
        .catch(err => {
          error.value = err;

          if (err.json) {
            return err.json.then(json => {
              error.value.message = json.message;
            });
          }
        })
        .then(() => {
          loading.value = false;
        });
    }

    const voteSubmit = async (e) => {
      e.preventDefault();
      axios
        .post(`${process.env.VUE_APP_API_URL}confirm-vote`, {
          pollId: route.params.id,
          otp: route.params.otp
        }, {
          withCredentials: true,
          headers: {
            'x-access-token': readCookies('token'),
            'Content-Type': 'application/json'
          }
      })
      .then( (response) => {
        console.log(response);
        voteRegistered.value = true;
      })
      .catch(function (error) {
        console.log(error);
      });
    }

    onMounted(() => {
      fetchData();
    });

    return {
      poll,
      voteRegistered,
      voteSubmit,
      loading,
      error,
      cookieInfo
    };
  }
}
</script>